/* Responsive embed styling for DexScreener chart */
#dexscreener-embed {
    position: relative;
    width: 100%;
    padding-bottom: 125%; /* Aspect ratio for smaller screens */
    background-color: #17171c; /* Dark background for the container */
    border-radius: 8px; /* Optional: smooth rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Optional: subtle shadow */
  }
  
  @media (min-width: 1400px) {
    #dexscreener-embed {
      padding-bottom: 65%; /* Adjust aspect ratio for larger screens */
    }
  }
  
  #dexscreener-embed iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
    border-radius: 8px; /* Optional: rounded corners for iframe */
  }
  