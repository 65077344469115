/* Global Dark Theme Styles */
  /*

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #17171c;
    color: #ffffff;
  }
  /*
  h1, h2, h3, h4, h5, h6 {
    color: #ffffff;
  }
  
  p {
    color: #cccccc;
  }
  
  a {
    color: #3b82f6;
    text-decoration: none;
  }
    */
  .lead {
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 1.5px; /* Adjust character spacing */
    color: #ffffff; /* Set font color to white */
  }
/*
  a:hover {
    text-decoration: underline;
  }
  
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px;
  }
  
  .card {
    background-color: #1e1e25;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .chart-container {
    background-color: #1e1e25;
    padding: 20px;
    border-radius: 8px;
  }
  */
  .violet-bold {
    color: violet;
    font-weight: bold;
  }
  
  .green-bold {
    color: #00ff00; /* Bright green */
    font-weight: bold;
  }
  
  .blue-bold {
    color: #008cff; /* Bright blue */
    font-weight: bold;
  }
  
  .yellow-bold {
    color: #ffcc00; /* Bright yellow */
    font-weight: bold;
  }
  .yellow {
    color: #ffcc00; /* Bright yellow */
    font-weight: normal;
  }
  
  .lead {
    font-size: 1.25rem;
    font-weight: 200;
    letter-spacing: 1.5px;
    color: #ffffff; /* Default text color: white */
    line-height: 1.6;
  }
  .under {
    text-decoration: underline;
  }
  
  .toggle-text {
    font-size: 1.1rem;
    margin-left: 5px;
  }