@keyframes blink {
    0% { background-color: #007bff; color: #ffffff; }
    50% { background-color: #d900ff; color: #000000; }
    100% { background-color: #007bff; color: #ffffff; }
  }
  
  .blinking {
    animation: blink 3.6s infinite;
    font-weight: bold;
    font-size:x-large;
    box-shadow: 0 0 10px 2px rgba(234, 46, 247, 0.7);
    transition: box-shadow 0.3s ease;
  }
  .blinkbtn {
    padding-top: 15px;
    font-size: x-large;
    padding-bottom: 15px;
    padding-right: 40px;
    padding-left: 40px;
    animation: pulse 1.5s infinite;
    box-shadow: 0 0 10px 2px rgba(215, 73, 233, 0.7);
    transition: transform 0.2s ease-in-out;
  }
  
  @keyframes pulseBinary {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);  /* Increase size to 110% */
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes pulseUnilevel {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);  /* Increase size to 110% */
    }
    100% {
      transform: scale(1);
    }
  }
  