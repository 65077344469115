/* 
  Optimized App.css
  - Provides improved light/dark mode support, responsive design, and maintainable custom styles.
  - This file is loaded after Tailwind’s base utilities (import './styles/tailwind.css').
*/

/* ---------- Base Resets & Typography ---------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Light Mode (Default) */
body {
  @apply bg-white text-black;
}

/* Dark Mode */
.dark body {
  @apply bg-bggrey text-white;
}
/*
html, body {
  height: 80%;
  font-family: 'Ubuntu', 'Roboto', 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--text-color);
}
*/
/* ---------- Root Container ---------- */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* ---------- Theme Variables ---------- */
:root {
  --primary: #00E898;
  --secondary: #5261DD;
  --tertiary: #F36523;
  --accent: #83C073;
  --highlight: #E7C14F;
  --background: #ffffff;
  --card-background: #f1f1f1;
  --text-color: #212529;
}

.dark{
  --primary: #00E898;
  --secondary: #5261DD;
  --tertiary: #F36523;
  --accent: #83C073;
  --highlight: #E7C14F;
  --background: #121212;
  --card-background: #2c2c2c;
  --text-color: #f1f1f1;
}


/* App.css */

.container {
  width: 100%;
  max-width: 1200px;
  /*margin: 0 auto;*/
  /*padding: 0px;*/
}
/*
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: #f9fafb;
  color: #1f2937;
}
*/
/* Constrain the maximum width of containers */
.container {
  max-width: 1200px;
}

/* Responsive container padding */
@media (max-width: 768px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* Ensure main content is not too wide */
.main-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

/* Footer styling for better mobile UX */
footer {
  padding: 1rem;
  text-align: center;
  background-color: #1f2937;
  color: #f9fafb;
}

/* Optional: Adjust headings and text sizes for mobile */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: 'Ubuntu', sans-serif;
}

@media (max-width: 640px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  p {
    font-size: 0.9rem;
  }
}


.newsletter {
  background-color: #f9f9f9;
}

.btn-item {
  color: #f9f9f9;
}

.btn-item:hover {
  color: var(--secondary);
}

.menu-item {
  color: #f9f9f9;
}

.menu-item:hover {
  color: var(--primary);
}
.footer-item {
  color: #212529;
}

.footer-item:hover {
  color: var(--primary);
}

.toggler {
  color: var(--secondary);
}
.toggler:hover {
  color: var(--primary);
}

/* TokenCard*/
.tc-btn {
  color: #f9f9f9;
}
.tc-btn:hover {
  color: var(--secondary);
}